@import "./../vars";

.datatable {
  box-shadow: 0 0 0 1px $cornflower-color;
  margin: 20px;
  border-radius: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      flex: 3;
      font-weight: 900;
      padding: 10px;
      font-size: 20px;
      color: $cornflower-color;
    }
    .p-button {
      color: $secondary-color;
      &.p-button-text:focus {
        box-shadow: inset 0 -3em 3em #eee;
      }
    }
    .p-dropdown {
      flex: 1;
    }
  }
  .p-datatable {
    position: relative;
    overflow-x: auto;
    th {
      min-width: 100px;
      padding: 10px;
    }
    img {
      width: 100px;
      object-fit: contain;
      max-height: 50px;
    }
    .p-datatable-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .p-datatable-tbody > tr {
      .hoverable-cell {
        &:hover {
          word-wrap: unset;
          white-space: initial;
        }
      }
    }
    .p-datatable-tbody > tr > td {
      text-align: center;
      .ar {
        direction: rtl;
      }
      .en {
        direction: ltr;
      }
      .long-text {
        max-width: 300px;
        padding: 0.5rem 0.5rem;
        white-space: inherit;
        word-wrap: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $logo-font;
      }
    }
    .p-column-header-content {
      justify-content: center;
    }
  }
  .p-column-header-content {
    .p-column-title {
      color: $logo-color;
    }
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: 10px;
    color: $logo-color;
  }
  .p-datatable .p-datatable-tbody > tr {
    word-break: break-word;
  }
  .p-paginator {
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
