.p-button {
  box-shadow: unset;
  transition: 0.1s ease-in-out;
  direction: rtl;
  .p-icon {
    margin-left: 4px;
  }
}

.p-button.info {
  border-color: unset;
  background-color: $logo-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $logo-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $logo-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.warning {
  border-color: unset;
  background-color: $warning-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $warning-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $warning-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.danger {
  border-color: unset;
  background-color: $danger-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $danger-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $danger-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.primary {
  border-color: unset;
  background-color: $primary-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $primary-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $primary-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.faded {
  border-color: unset;
  color: $white;
  background-color: $white-faded;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $white-faded, $amount: 10);
  }
  &:focus {
    outline: 2px solid $white-faded; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}

.p-button.dark {
  border-color: unset;
  color: $white;
  background-color: $secondary-color;
  margin-top: 20px;
  &:hover {
    background-color: darken($color: $secondary-color, $amount: 10);
  }
  &:focus {
    outline: 2px solid $secondary-color; /* Add custom outline on focus */
  }
  &:active {
    transform: scale(1.1);
  }
}
