@import "../../assets/vars";
.about-us-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  background-color: $white;
  border-radius: 20px;
  .card-header {
    text-align: center;
    color: $cornflower-color;
    .card-title {
      font-weight: bold;
    }
  }
  .card-link {
    text-decoration: none;
    color: $logo-font;
    background-color: rgba(0, 0, 0, 0.053);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    word-break: break-all;
  }
  .p-inputtext {
    border-color: $secondary-color;
  }
  .p-inputtext:enabled:hover {
    border-color: $logo-color;
  }
  img {
    width: 200px;
  }
}
