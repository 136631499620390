@import "../../assets/vars";
.about-us {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin: 30px 30px 0 30px;
    padding: 20px;
    background-color: $white;
    h2 {
      color: $cornflower-color;
    }
  }
  .main-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    margin: 30px 30px 0 30px;
    padding: 20px;
    background-color: $white;
    .arabic {
      text-align: right;
      border-left: 1px solid $logo-font;
      padding: 10px;
    }
    .english {
      text-align: left;
      padding: 10px;
    }
  }
  .grid {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: $laptop-bp) {
  .about-us {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
    .main-desc {
      flex-direction: column;
      .arabic {
        border-left: none;
        border-top: 1px solid $logo-font;
      }
    }
  }
}
