@import "../../assets/vars";
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  .background-image {
    background-image: url("../../assets/images/cover-image.jpg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
  }
  &-form {
    width: 50vw;
    border-radius: 20px;
    max-width: 450px;
    text-align: center;
    padding: 20px 40px;
    // background-color: $white;
    .logo {
      img {
        width: 200px;
      }
    }
    .input-wrapper {
      h5 {
        margin: 10px 0;
        font-size: 15px;
        text-align: left;
      }
    }
  }
}
