@import "../../assets/vars";
.sidebar {
  .sticky {
    position: sticky;
    z-index: 99;
    top: 0;
    left: 0;
  }
  background-color: $white;
  width: 200px;
  min-width: 150px;
  min-height: 100vh;

  &-logo {
    text-align: center;
    padding: 20px 0;
    .logo-image {
      width: auto;
      height: 80px;
      transform: scale(2);
    }
  }

  .sidebar-list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    i {
      margin-right: 5px;
      font-size: 18px;
      color: $logo-color;
    }
    a {
      font-size: 14px;
      color: $white;
      text-decoration: none;
    }
    .sidebar-item {
      padding: 12px;
      color: $logo-color;
      cursor: pointer;
      padding-left: 10px;
      font-weight: bold;
      transition: background-color 0.2s;
      position: relative;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      font-size: 16px;
      align-items: center;
      margin-bottom: 5px;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: $cornflower-color;
        color: $white;
        transition: 0.3s all ease-in-out;
        i {
          color: $white;
          transition: 0.3s all ease-in-out;
        }
      }
      &.visited {
        background-color: $cornflower-color;
        color: $white;
        i {
          color: $white;
        }
      }
    }
  }
}
