.custom-input {
  width: 100%;
  height: 42px;
  padding: 8px;
  min-height: 20px;
  font-size: 15px;
  color: $logo-font;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: 0.5s ease-in-out;
  &:hover,
  &:focus {
    outline: 2px solid $logo-color;
    outline-offset: -2px;
    transition: 0.5s ease-in-out;
    border-color: $logo-color;
  }
  &.ar {
    direction: rtl;
    text-align: right;
  }
}
.p-inputtext:enabled:focus {
  box-shadow: unset;
}
.p-inputtext {
  min-height: 20px;
  color: $logo-font;
  font-size: 15px;
}
