@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./vars";
@import "./utils/button.scss";
@import "./utils/input.scss";
@import "./utils/dialog.scss";
@import "./utils/dropdown.scss";
@import "./utils/inputnumber.scss";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: sans-serif;
}
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::selection {
  color: #eee;
  background: #86898d;
}
body {
  background-color: $white-faded;
}
.app {
  display: flex;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    .p-dropdown-item-group {
      text-align: center;
      background-color: $primary-color;
      color: $white-faded;
    }
    border-radius: 20px;
    flex: 1;
  }
}
.p-toast-message {
  .p-toast-message-icon {
    margin-right: 10px;
  }
}
.p-dialog-content{
  height: 80vh !important;
}
@import "./utils/breakpoints.scss";
