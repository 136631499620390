@import "../../assets/vars";
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 15px;

  .username {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    span,
    i {
      color: $logo-color;
      font-weight: bold;
    }
  }

  .nav-links {
    list-style-type: none;
    display: flex;
    h4 {
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      text-decoration: none;
      color: $logo-color;
      font-weight: bold;
      padding: 15px;
      border-radius: 10px;
      &:hover {
        transition: 0.2s ease;
        background-color: $cornflower-color;
        color: $white;
      }
    }
    li {
      margin-right: 20px;
      text-decoration: none;
    }
  }
}
