@import "./../vars";
@media (max-width: $screen-bp) {
  // .datatable {
  //   .p-datatable {
  //     .p-datatable-tbody > tr > td {
  //       text-align: center;
  //       .ar {
  //         direction: rtl;
  //       }
  //       .en {
  //         direction: ltr;
  //       }
  //       .long-text {
  //         max-width: 100px;
  //         padding: 0.5rem 0.5rem;
  //         white-space: inherit;
  //         word-wrap: break-word;
  //         overflow: hidden;
  //         white-space: nowrap;
  //         text-overflow: ellipsis;
  //         color: $logo-font;
  //       }
  //     }
  //   }
  // }
}
@media (max-width: $laptop-bp) {
}
@media (max-width: $tablet-bp) {
  .app {
    display: flex;
    flex-direction: column;
    .sidebar {
      width: 100%;
      min-height: unset;
      ul {
        display: block;
      }
    }
  }
  .login {
    .background-image {
      display: none;
    }
    background-image: url("../../assets/images/cover-image.jpg");
    background-size: cover;
    height: 100vh;

    &-form {
      width: 80%;
      background-color: $white;
    }
  }
}
@media (max-width: $mobile-bp) {
}
