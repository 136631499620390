@import "./../vars";
.p-dialog {
  min-width: max-content;
  min-height: max-content;
  width: 50vw;
  .container {
    padding: 10px;
    h5 {
      text-align: center;
      font-size: 18px;
      padding: 10px;
    }
    h4 {
      margin: 10px;
      font-size: 18px;
      text-align: center;
    }
    .check-btn {
      border-top: 2px solid #eee;
      padding-top: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .p-inputswitch-slider {
        box-shadow: unset;
        background-color: $primary-color;
      }
      .p-inputswitch-checked {
        .p-inputswitch-slider {
          background-color: $primary-color;
        }
      }
    }
  }
  .p-dialog-header {
    color: $white-faded;
    background-color: $logo-color;
    padding: 10px;
    .p-dialog-title {
      font-size: 20px;
      font-weight: bold;
    }
    .p-dialog-header-icon {
      color: white;
      background-color: $danger-color;
      &:hover {
        background-color: darken($color: $danger-color, $amount: 10);
      }
    }
  }
  .input-wrapper {
    h2 {
      text-align: center;
      margin: 10px;
    }
    margin: 20px 0 20px 0;
  }
  .p-button-label {
    padding-right: 10px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .danger-header {
    .p-dialog-header-icon {
      color: black;
    }
    background-color: $danger-color;
    gap: 10px;
  }
  .popup-content {
    padding: 20px;
    gap: 10px;
  }
  .p-dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p-confirm-dialog-reject {
      box-shadow: unset;
      border: unset;
      width: 25%;
      padding-right: 15px;
      margin: 0;
      color: gray;
    }
    .p-confirm-dialog-accept {
      box-shadow: unset;
      border: unset;
      width: 25%;
      padding-right: 15px;
      margin: 0;
      background-color: $logo-color;
      color: white;
    }
  }
  .file-upload {
    text-align: center;
    margin: 20px;
    .p-fileupload {
      .p-fileupload-choose {
        width: auto;
        border: unset;
        box-shadow: unset;
        background-color: $logo-color;
        &:hover {
          background-color: darken($color: $logo-color, $amount: 10);
        }
      }
    }
    .p-fileupload-choose {
      width: 30vw;
    }
    .image-preview {
      img {
        width: 150px;
      }
    }
  }
  .section {
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
    padding: 10px;
    margin: 30px auto;
    border-radius: 5px;
  }
}
